// import React, { useState } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import './login.css';
// import { FaUser, FaLock, FaGoogle, FaFacebook, FaTwitter } from 'react-icons/fa'; // Import icons

// const LoginPage = () => {
//   const [formData, setFormData] = useState({
//     usernameOrPhone: '',
//     password: ''
//   });
//   const [error, setError] = useState('');
//   const [isLoggedIn, setIsLoggedIn] = useState(false); // State to track login status
//   const [username, setUsername] = useState(''); // State to store username
//   const navigate = useNavigate();

//   const handleChange = (e) => {
//     const { name, value } = e.target;

//     // Restrict spaces and lowercase letters for usernameOrPhone
//     if (name === 'usernameOrPhone') {
//       const processedValue = value.toLowerCase().replace(/\s+/g, ''); // Remove spaces and convert to lowercase
//       setFormData((prevState) => ({
//         ...prevState,
//         [name]: processedValue,
//       }));
//     } else {
//       setFormData((prevState) => ({
//         ...prevState,
//         [name]: value,
//       }));
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await fetch('/api/user-login', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(formData), // Sending updated formData
//       });
//       const data = await response.json();
//       if (response.ok) {
//         localStorage.setItem('token', data.token);
//         localStorage.setItem('username', formData.usernameOrPhone); // Save username in localStorage
//         setUsername(formData.usernameOrPhone);
//         setIsLoggedIn(true);
        

//         navigate('/shop');
//         window.location.reload();
//       } else {
//         setError(`Login failed: ${data.message}`);
//         setIsLoggedIn(false);
//       }
//     } catch (error) {
//       console.error('Error:', error);
//       setError('Error logging in user');
//       setIsLoggedIn(false);
//     }
//   };

//   return (
//     <div className="main">
//       <section className="sign-in">
//         <div className="login-container">
//           <div className="signin-content">
//             <div className="signin-form">
//               <h4 className="form-title1">Sign-in</h4>
//               <form method="post" className="register-form" id="login-form" onSubmit={handleSubmit}>
//                 <div className="form-group">
//                   <FaUser className="icon" />
//                   <input
//                     type="text"
//                     name="usernameOrPhone"
//                     id="usernameOrPhone"
//                     placeholder="Enter your username or phone number"
//                     className="input2"
//                     value={formData.usernameOrPhone}
//                     onChange={handleChange}
//                   />
//                 </div>
//                 <div className="form-group">
//                   <FaLock className="icon" />
//                   <input
//                     type="password"
//                     name="password"
//                     id="password"
//                     placeholder="Enter your password"
//                     className="input2"
//                     value={formData.password}
//                     onChange={handleChange}
//                   />
//                 </div>
//                 <div className="forget">
//                   <Link to="/forget-password-reset"><h>Forget Password</h></Link>
//                 </div>
//                 {error && <div className="error-message">{error}</div>}
//                 <div className="form-group form-button">
//                   <input
//                     type="submit"
//                     name="signin"
//                     id="signin"
//                     className="form-submit"
//                     value="Log in"
//                   />
//                 </div>
//                 {isLoggedIn && username ? (
//                   <span className="signup-image-link">Hello, {username}</span>
//                 ) : (
//                   <Link to="/register" className="signup-image-link">Create an account</Link>
//                 )}
//               </form>
//             </div>
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default LoginPage;
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './login.css';
import axios from "axios";
import { FaUser, FaWhatsapp, FaLock, FaGoogle, FaFacebook, FaTwitter } from 'react-icons/fa';

const LoginPage = () => {
  const [isOtpLogin, setIsOtpLogin] = useState(true); // State to toggle between OTP and password login
  const [formData, setFormData] = useState({
    phno: '',
    otp: '',
    usernameOrPhone: '',
    password: '',
  });
  const [error, setError] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false); // State to track OTP sent status
  const [otpCountdown, setOtpCountdown] = useState(0);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    if (name === 'phno') {
      const numericValue = value.replace(/\D/g, ''); // Remove non-numeric characters
      if (numericValue.length <= 10) { // Restrict length to 10
        setFormData((prevState) => ({
          ...prevState,
          [name]: numericValue,
        }));
      }
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  

  const validatePhoneNumber = (phno) => {
    return phno.length === 10;
  };
  // Send OTP
  const handleSendOtp = async (e) => {
    e.preventDefault();

    // Ensure phone number is valid before sending OTP
    if (!validatePhoneNumber(formData.phno)) {
      setError('Phone number must be 10 digits.');
      return;
    }

    try {
      const response = await axios.post("/api/send-otp", { phoneNumber: formData.phno });
      if (response.data.success) {
        setError("OTP sent successfully.");
        setIsOtpSent(true); // OTP sent successfully, show OTP input
        setOtpCountdown(30);

        // Start a timer for the countdown
      const interval = setInterval(() => {
        setOtpCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(interval); // Stop the timer
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      setError("Error sending OTP.");
    }
  };

  // Verify OTP
  const handleVerifyOtp = async (e) => {
  e.preventDefault();

  if (!formData.otp) {
    setError('Please enter the OTP');
    return;
  }

  try {
    // Send API request to verify OTP
    const verifyResponse = await axios.post('/api/user-login-otp', {
      phoneNumber: formData.phno, // Raw phone number without prefix
      otp: formData.otp,
    });

    const { data } = verifyResponse;

    if (data.success) {
      // Store the token in localStorage
      localStorage.setItem('token', data.token);
      // Navigate to the shop page
      navigate('/shop');
      // Reload the page to reflect authentication state
      window.location.reload();
    } else {
      setError(`OTP verification failed: ${data.message}`);
    }
  } catch (error) {
    // Handle API error
    setError(error.response?.data?.message || 'Error verifying OTP');
  }
};


  // Handle username/password login
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/api/user-login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          usernameOrPhone: formData.usernameOrPhone,
          password: formData.password,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        localStorage.setItem('token', data.token);
        localStorage.setItem('fname', data.fname);
        navigate('/shop');
        window.location.reload();
      } else {
        setError(`Login failed: ${data.message}`);
      }
    } catch (error) {
      setError('Error logging in user');
    }
  };

  return (
    <div className="main">
      <section className="sign-in">
        <div className="login-container">
          <div className="signin-content">
            <div className="signin-form">
              <h4 className="form-title1">Sign-in</h4>
              {isOtpLogin ? (
                <form className="register-form">
                  <div className="form-group">
                    <FaWhatsapp className="icon-whatsapp" />
                    <input
                      type="text"
                      name="phno"
                      placeholder="Enter your Whatsapp number"
                      className="input2"
                      value={formData.phno}
                      onChange={handleChange}
                    />
                  </div>
                  {isOtpSent && (
                    <div className="form-group">
                      <FaLock className="icon" />
                      <input
                        type="text"
                        name="otp"
                        placeholder="Enter OTP"
                        className="input2"
                        value={formData.otp}
                        onChange={handleChange}
                      />
                    </div>
                  )}
                  {error && <div className="error-message">{error}</div>}
                  <div className="form-group form-button">
                    <button
                      type="button"
                      className={isOtpSent ? "resend-otp-button": "send-otp-button"}
                      onClick={handleSendOtp}
                      disabled={!formData.phno || !validatePhoneNumber(formData.phno) || otpCountdown > 0}
                    >
                      {otpCountdown > 0 ? `Resend OTP (${otpCountdown}s)` : isOtpSent ? "Resend OTP":"Send OTP"}
                    </button>

                    <button
                      type="button"
                      className="form-submit"
                      onClick={handleVerifyOtp}
                      disabled={!isOtpSent}
                    >
                      Login
                    </button>
                  </div>
                  <p className="switch-method" onClick={() => setIsOtpLogin(false)}>
                    Login using username/password
                  </p>
                </form>
              ) : (
                <form className="register-form" onSubmit={handleSubmit}>
                  <div className="form-group">
                    <FaUser className="icon" />
                    <input
                      type="text"
                      name="usernameOrPhone"
                      placeholder="Enter your username or phone number"
                      className="input2"
                      value={formData.usernameOrPhone}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <FaLock className="icon" />
                    <input
                      type="password"
                      name="password"
                      placeholder="Enter your password"
                      className="input2"
                      value={formData.password}
                      onChange={handleChange}
                    />
                  </div>
                  {error && <div className="error-message">{error}</div>}
                  <div className="form-group form-button">
                    <input type="submit" className="form-submit" value="Log in" />
                  </div>
                  <p className="switch-method" onClick={() => setIsOtpLogin(true)}>
                    Login using OTP
                  </p>
                  <div className="forget">
                  <Link to="/forget-password-reset"><h>Forget Password</h></Link>
                </div>
                </form>
              )}
              <Link to="/register" className="signup-image-link">
                Create an account
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LoginPage;
