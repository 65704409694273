import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx'; // Importing XLSX library for exporting to Excel
import './updateOrderInBulk.css';

const UpdateOrderInBulk = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('All');
  const [yearFilter, setYearFilter] = useState('');
  const [monthFilter, setMonthFilter] = useState('');
  const [dayFilter, setDayFilter] = useState('');
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [updateStatus, setUpdateStatus] = useState('');
  const [searchTerm, setSearchTerm] = useState(''); // For search functionality

  // Fetch users and orders
  useEffect(() => {
    const fetchUsersOrders = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('/api/admin/users-orders', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.users) {
          setUsers(response.data.users);
          console.log(users);
        } else {
          alert('No users or orders found.');
        }
      } catch (error) {
        console.error('Error fetching users and orders:', error);
        alert('An error occurred while fetching users and orders.');
      } finally {
        setLoading(false);
      }
    };

    fetchUsersOrders();
  }, []);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (name === 'status') setFilter(value);
    if (name === 'year') setYearFilter(value);
    if (name === 'month') setMonthFilter(value);
    if (name === 'day') setDayFilter(value);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value); // Update search term as user types
  };

  const handleOrderSelect = (orderId) => {
    setSelectedOrders((prevSelected) => {
      if (prevSelected.includes(orderId)) {
        return prevSelected.filter((id) => id !== orderId);
      } else {
        return [...prevSelected, orderId];
      }
    });
  };

  const handleSelectAll = () => {
    const filteredOrders = filteredAndSortedUsers.flatMap(user =>
      user.orders.map(order => order.orderId)
    );

    if (selectAll) {
      setSelectedOrders([]); // Deselect all
    } else {
      setSelectedOrders(filteredOrders); // Select only filtered orders
    }
    setSelectAll(!selectAll);
  };

  const formatDate = (dateString) => {
    if (!dateString) {
      return 'N/A';  // If no date is provided, return "N/A"
    }

    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const filteredAndSortedUsers = users
    .map(user => ({
      ...user,
      orders: user.orders
        .filter(order => {
          const orderDate = new Date(order.orderDate);
          const orderYear = orderDate.getFullYear().toString();
          const orderMonth = (orderDate.getMonth() + 1).toString().padStart(2, '0');
          const orderDay = orderDate.getDate().toString().padStart(2, '0');

          // Check if the search term matches Order ID, Invoice ID, or Phone Number
          const matchesSearch =
          user.fname?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.lname?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.username?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            order.orderId?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            order.invoiceId?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.phno?.toLowerCase().includes(searchTerm.toLowerCase());

          return (
            matchesSearch && // Include orders that match the search term
            (filter === 'All' || order.orderStatus === filter) &&
            (!yearFilter || orderYear === yearFilter) &&
            (!monthFilter || orderMonth === monthFilter) &&
            (!dayFilter || orderDay === dayFilter)
          );
        })
        .sort((a, b) => new Date(b.orderDate) - new Date(a.orderDate)) // Sort orders by orderDate (newest first)
    }))
    .filter(user => user.orders.length > 0); // Only include users with orders after filtering

  const handleBulkUpdate = async () => {
    if (selectedOrders.length === 0) {
      alert('Please select at least one order to update.');
      return;
    }

    if (!updateStatus) {
      alert('Please select an update status.');
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const response = await axios.put('/api/admin/update-orders', {
        orderIds: selectedOrders,
        status: updateStatus,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        alert('Orders updated successfully!');
        setSelectedOrders([]);
        setSelectAll(false);
        setUpdateStatus('');
      }
    } catch (error) {
      console.error('Error updating orders:', error);
      alert('An error occurred while updating the orders.');
    }
  };

  // New function to export selected orders to Excel
  const exportToExcel = () => {
    const dataForExcel = filteredAndSortedUsers.flatMap(user =>
      user.orders
        .filter(order => selectedOrders.includes(order.orderId)) // Only include selected orders
        .map(order => ({
          'Name': `${user.fname} ${user.lname}`,
          'Email': user.email,
          'Username': user.username,
          'Order ID': order.orderId,
          'Phone Number': user.phno,
          'Invoice ID': order.invoiceId || 'N/A',
          'Payment Method': order.paymentMethod || 'N/A',
          'Order Status': order.orderStatus,
          'Order Date': formatDate(order.orderDate),
          'Estimated Delivery Date': formatDate(order.estimatedDeliveryDate),
          'Product Name': order.items && order.items.length > 0 ? order.items.map(item => item.pdname).join(', ') : 'N/A',
          'Product ID': order.items && order.items.length > 0 ? order.items.map(item => item.productId).join(', ') : 'N/A',
          'Product Color': order.items && order.items.length > 0 ? order.items.map(item => item.color).join(', ') : 'N/A',
          'Quantity': order.items && order.items.length > 0 ? order.items.map(item => item.quantity).join(', ') : 'N/A',
          'SKU ID': order.items && order.items.length > 0 ? order.items.map(item => item.skuId).join(', ') : 'N/A',
          'Listing Price': order.items && order.items.length > 0 ? order.items.map(item => item.listingPrice).join(', ') : 'N/A',
          'Payable Price': order.items && order.items.length > 0 ? order.items.map(item => item.price).join(', ') : 'N/A',
          'Discount': order.items && order.items.length > 0 ? order.items.map(item => item.discount).join(', ') : 'N/A',
          'Address': order.address && order.address.length > 0
            ? `${order.address[0].addressline1}, ${order.address[0].addressline2 || ''}`
            : 'N/A',
          'City': order.address && order.address.length > 0 ? order.address[0].city : 'N/A',
          'State': order.address && order.address.length > 0 ? order.address[0].state : 'N/A',
          'Postal Code': order.address && order.address.length > 0 ? order.address[0].postalCode : 'N/A',
          'Weight': order.items && order.items.length > 0 ? order.items.map(item => item.weight).join(', ') : 'N/A',
          'Length': order.items && order.items.length > 0 ? order.items.map(item => item.length).join(', ') : 'N/A',
          'Height': order.items && order.items.length > 0 ? order.items.map(item => item.height).join(', ') : 'N/A',
          'Breadth': order.items && order.items.length > 0 ? order.items.map(item => item.breadth).join(', ') : 'N/A',

        }))
    );

    // Check if there's data to export
    if (dataForExcel.length === 0) {
      alert('No orders selected for export.');
      return;
    }

    const worksheet = XLSX.utils.json_to_sheet(dataForExcel);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Selected Orders');

    XLSX.writeFile(workbook, 'SelectedOrdersData.xlsx');
  };

  // New function to generate invoice IDs for selected orders
  const handleGenerateInvoiceIds = async () => {
    if (selectedOrders.length === 0) {
      alert('Please select at least one order to generate invoice ID.');
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const response = await axios.put('/api/admin/generate-invoice-ids', {
        orderIds: selectedOrders,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        alert('Invoice IDs generated successfully!');
        setSelectedOrders([]);
        setSelectAll(false);
      }
    } catch (error) {
      console.error('Error generating invoice IDs:', error);
      alert('An error occurred while generating invoice IDs.');
    }
  };

//FUNCTION TO ADD PROGRESS BAR
const generateProgressBar = (orders) => {
  const statusCounts = { delivered: 0, cancelled: 0, pending: 0, shipped: 0 };

  orders.forEach((order) => {
    switch (order.orderStatus.toLowerCase()) {
      case 'delivered':
        statusCounts.delivered += 1;
        break;
      case 'canceled':
        statusCounts.cancelled += 1;
        break;
      case 'pending':
        statusCounts.pending += 1;
        break;
      case 'shipped':
        statusCounts.shipped += 1;
        break;
      default:
        break;
    }
  });

  const totalOrders = orders.length;
  return {
    deliveredPercent: (statusCounts.delivered / totalOrders) * 100 || 0,
    cancelledPercent: (statusCounts.cancelled / totalOrders) * 100 || 0,
    pendingPercent: (statusCounts.pending / totalOrders) * 100 || 0,
    shippedPercent: (statusCounts.shipped / totalOrders) * 100 || 0,
  };
};



  if (loading) {
    return <p>Loading users and orders...</p>;
  }

  return (
    <div className="admin-user-orders-container">
      <h2>All Users' Orders</h2>
      <div className="filter-options">
        <label>Search: </label>
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="Search by Order ID, Invoice ID, Phone"
        />
        <label>Filter by Status: </label>
        <select name="status" value={filter} onChange={handleFilterChange}>
          <option value="All">All</option>
          <option value="pending">Pending</option>
          <option value="canceled">Canceled</option>
          <option value="delivered">Delivered</option>
          <option value="shipped">Shipped</option>
        </select>
        <br /><br />
        <label>Filter by Year: </label>
        <input
          type="number"
          name="year"
          value={yearFilter}
          onChange={handleFilterChange}
          placeholder="YYYY"
        />

        <label>Filter by Month: </label>
        <input
          type="number"
          name="month"
          value={monthFilter}
          onChange={handleFilterChange}
          placeholder="MM"
          min="1"
          max="12"
        />

        <label>Filter by Day: </label>
        <input
          type="number"
          name="day"
          value={dayFilter}
          onChange={handleFilterChange}
          placeholder="DD"
          min="1"
          max="31"
        />
      </div>

      {filteredAndSortedUsers.length === 0 ? (
        <p>No users or orders found.</p>
      ) : (
        <div>
          <div className="select-all-container">
            <input
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAll}
            />
            <label>Select All</label>
          </div>
          <p>Total Selected Orders: {selectedOrders.length}</p>
          <table className="orders-table">
            <thead>
              <tr>
                <th>Orders History</th>
                <th>Select</th>
                <th>User</th>
                <th>Email</th>
                <th>Username</th>
                <th>Order ID</th>
                <th>Invoice ID</th>
                <th>Status</th>
                <th>Order Date</th>
                <th>Phone Number</th>
                <th>Estimated Delivery Date</th>
                <th>Payment Method</th>
                <th>Product Name</th>
                <th>Product ID</th>
                <th>Product Color</th> {/* New Product Color column */}
                <th>Quantity</th>
                <th>SKU ID</th>
                <th>Listing Price</th> {/* New Listing Price column */}
                <th>Payable Price</th>
                <th>Discount</th>
                <th>Address line 1</th>
                <th>Address line 2</th>
                <th>City</th>
                <th>State</th>
                <th>Postal Code</th>
                <th>Weight</th>
                <th>Length</th>
                <th>Breadth</th>
                <th>Height</th>
                <th>Total Orders by customer</th>
              </tr>
            </thead>
            <tbody>
  {filteredAndSortedUsers.map((user) => {
    // Generate progress bar for all orders of this customer
    const { deliveredPercent, cancelledPercent, pendingPercent, shippedPercent } = generateProgressBar(user.orders);

    return user.orders.map((order, orderIndex) => (
      <tr
        key={order.orderId}
        className={selectedOrders.includes(order.orderId) ? 'selected-row' : ''}
      >
        {/* Progress bar only on the first row for this customer */}
        {orderIndex === 0 && (
          <td rowSpan={user.orders.length}>
            <div className="progress-bar-container">
              <div className="progress-bar">
                <div
                  className="progress-segment delivered"
                  style={{ width: `${deliveredPercent}%` }}
                ></div>
                <div
                  className="progress-segment cancelled"
                  style={{ width: `${cancelledPercent}%` }}
                ></div>
                <div
                  className="progress-segment pending"
                  style={{ width: `${pendingPercent}%` }}
                ></div>
                <div
                  className="progress-segment shipped"
                  style={{ width: `${shippedPercent}%` }}
                ></div>
              </div>
            </div>
          </td>
        )}

        {/* Table columns for individual orders */}
        <td>
          <input
            type="checkbox"
            checked={selectedOrders.includes(order.orderId)}
            onChange={() => handleOrderSelect(order.orderId)}
          />
        </td>
        <td>{user.fname} {user.lname}</td>
        <td>{user.email}</td>
        <td>{user.username}</td>
        <td>{order.orderId}</td>
        <td>{order.invoiceId || 'N/A'}</td>
        <td>{order.orderStatus}</td>
        <td>{formatDate(order.orderDate)}</td>
        <td>{user.phno}</td>
        <td>{formatDate(order.estimatedDeliveryDate)}</td>
        <td>{order.paymentMethod || 'N/A'}</td>
        <td>{order.items && order.items.length > 0 ? order.items.map(item => item.pdname).join(', ') : 'N/A'}</td>
        <td>{order.items && order.items.length > 0 ? order.items.map(item => item.productId).join(', ') : 'N/A'}</td>
        <td>{order.items && order.items.length > 0 ? order.items.map(item => item.color).join(', ') : 'N/A'}</td>
        <td>{order.items && order.items.length > 0 ? order.items.map(item => item.quantity).join(', ') : 'N/A'}</td>
        <td>{order.items && order.items.length > 0 ? order.items.map(item => item.skuId).join(', ') : 'N/A'}</td>
        <td>{order.items && order.items.length > 0 ? order.items.map(item => item.listingPrice).join(', ') : 'N/A'}</td>
        <td>{order.items && order.items.length > 0 ? order.items.map(item => item.price).join(', ') : 'N/A'}</td>
        <td>{order.items && order.items.length > 0 ? order.items.map(item => item.discount).join(', ') : 'N/A'}</td>
        <td>{order.address && order.address.length > 0 ? order.address[0].addressline1 : 'N/A'}</td>
        <td>{order.address && order.address.length > 0 ? order.address[0].addressline2 : 'N/A'}</td>
        <td>{order.address && order.address.length > 0 ? order.address[0].city : 'N/A'}</td>
        <td>{order.address && order.address.length > 0 ? order.address[0].state : 'N/A'}</td>
        <td>{order.address && order.address.length > 0 ? order.address[0].postalCode : 'N/A'}</td>
        <td>{order.items && order.items.length > 0 ? order.items.map(item => item.weight).join(', ') : 'N/A'}</td>
        <td>{order.items && order.items.length > 0 ? order.items.map(item => item.length).join(', ') : 'N/A'}</td>
        <td>{order.items && order.items.length > 0 ? order.items.map(item => item.breadth).join(', ') : 'N/A'}</td>
        <td>{order.items && order.items.length > 0 ? order.items.map(item => item.height).join(', ') : 'N/A'}</td>
      </tr>
    ));
  })}
</tbody>

          </table>

          <div className="bulk-update-section">
            <select value={updateStatus} onChange={(e) => setUpdateStatus(e.target.value)}>
              <option value="">Select Status</option>
              <option value="pending">Pending</option>
              <option value="delivered">Delivered</option>
              <option value="canceled">Canceled</option>
              <option value="shipped">Shipped</option>
            </select>
            <button class="update-order-btn" onClick={handleBulkUpdate}>Update Selected Orders</button>
            <button class="update-order-btn" onClick={handleGenerateInvoiceIds}>Generate Invoice ID for Selected Orders</button>
          </div>
          <button onClick={exportToExcel}>Export Selected Orders to Excel</button>
        </div>
      )}
    </div>
  );
};

export default UpdateOrderInBulk;
