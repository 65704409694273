import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx'; // Import XLSX library
import './manageusers.css';
import { FaQuestionCircle } from 'react-icons/fa';

const ManageUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showOrders, setShowOrders] = useState({});
  const [showAddresses, setShowAddresses] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [editing, setEditing] = useState(null); // Track which user is being edited for security question/answer

  useEffect(() => {
    fetchUsersData();
  }, []);

  const fetchUsersData = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/api/admin/users-orders', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.users) {
        setUsers(response.data.users);
        setFilteredUsers(response.data.users);
      } else {
        alert('No users or orders found.');
      }
    } catch (error) {
      console.error('Error fetching users and orders:', error);
      alert('An error occurred while fetching users and orders.');
    } finally {
      setLoading(false);
    }
  };

  const toggleOrdersVisibility = (username) => {
    setShowOrders((prevShowOrders) => ({
      ...prevShowOrders,
      [username]: !prevShowOrders[username],
    }));
  };

  const toggleAddressesVisibility = (username) => {
    setShowAddresses((prevShowAddresses) => ({
      ...prevShowAddresses,
      [username]: !prevShowAddresses[username],
    }));
  };

  const handleSearch = () => {
    const query = searchQuery.toLowerCase();
    const filtered = users.filter((user) =>
      (user.username && user.username.toLowerCase().includes(query)) ||
      (user.fname && user.fname.toLowerCase().includes(query)) ||
      (user.lname && user.lname.toLowerCase().includes(query)) ||
      (user.email && user.email.toLowerCase().includes(query)) || // Search by email
      (user.phno && user.phno.includes(query)) // Search by phone number
    );
    setFilteredUsers(filtered);
  };

  const handleEditSecurityQuestion = (username) => {
    setEditing(username);
  };

  const handleSaveSecurityQuestion = async (username) => {
    const updatedSecurityData = {
      securityQuestion: document.getElementById(`security-question-${username}`).value,
      securityAnswer: document.getElementById(`security-answer-${username}`).value,
    };

    try {
      const token = localStorage.getItem('token');
      await axios.put(`/api/admin/users-orders/${username}`, updatedSecurityData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setEditing(null); // Stop editing
      fetchUsersData(); // Refresh data
    } catch (error) {
      console.error('Error updating security question/answer:', error);
    }
  };

  const handleCancelEdit = () => {
    setEditing(null); // Close the edit mode without saving
  };

  const generateProgressBar = (orders) => {
    const totalOrders = orders.length;
    const statusCounts = {
      delivered: 0,
      cancelled: 0,
      pending: 0,
      shipped: 0,
    };

    // Count the number of orders per status
    orders.forEach((order) => {
      if (order.orderStatus === 'delivered') statusCounts.delivered++;
      if (order.orderStatus === 'canceled') statusCounts.cancelled++;
      if (order.orderStatus === 'pending') statusCounts.pending++;
      if (order.orderStatus === 'shipped') statusCounts.shipped++;
    });

    // Calculate percentages for each status
    return {
      deliveredPercent: (statusCounts.delivered / totalOrders) * 100,
      cancelledPercent: (statusCounts.cancelled / totalOrders) * 100,
      pendingPercent: (statusCounts.pending / totalOrders) * 100,
      shippedPercent: (statusCounts.shipped / totalOrders) * 100,
    };
  };

  const exportToExcel = () => {
    // Combine all data into a single array
    const combinedData = filteredUsers.flatMap((user) => {
      const { deliveredPercent, cancelledPercent, pendingPercent, shippedPercent } = generateProgressBar(user.orders);

      return user.orders.map((order) => {
        const address = user.addresses[0] || {}; // Use the first address or empty object if none

        return {
          'User Name': `${user.fname} ${user.lname}`,
          Username: user.username,
          Email: user.email,
          'Phone Number': user.phno,
          'Total Orders': user.orders.length,
          Delivered: `${deliveredPercent.toFixed(2)}%`,
          Cancelled: `${cancelledPercent.toFixed(2)}%`,
          Pending: `${pendingPercent.toFixed(2)}%`,
          Shipped: `${shippedPercent.toFixed(2)}%`,
          'Order ID': order.orderId,
          'Order Status': order.orderStatus,
          'Order Date': new Date(order.orderDate).toLocaleDateString(),
          'Address Line 1': address.addressLine1 || '-',
          'Address Line 2': address.addressLine2 || '-',
          City: address.city || '-',
          State: address.state || '-',
          'Postal Code': address.postalCode || '-',
          Country: address.country || '-',
          'Default Address': address.isDefault ? 'Yes' : 'No',
        };
      });
    });

    // Convert the data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(combinedData);

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Users_Data');

    // Export the workbook as an Excel file
    XLSX.writeFile(workbook, 'Users_Data.xlsx');
  };


  if (loading) {
    return <p>Loading users and orders...</p>;
  }

  return (
    <div className="manage-user-admin-manage-users-container">
      <h2>Manage Users</h2>
      <div className="manage-user-search-container">
        <input
          type="text"
          placeholder="Search by name, username, email, or phone number"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button onClick={handleSearch}>Search</button>
        <button onClick={exportToExcel} className="manage-user-export-button">
          Export to Excel
        </button>
      </div>
      {filteredUsers.length === 0 ? (
        <p>No users found.</p>
      ) : (
        <table className="manage-user-users-table">
          <thead>
            <tr>
              <th>Progress</th>
              <th>Name</th>
              <th>Username</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Total Orders</th>
              <th>Security Question</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user) => {
              const { deliveredPercent, cancelledPercent, pendingPercent, shippedPercent } = generateProgressBar(user.orders);

              return (
                <React.Fragment key={user.username}>
                  <tr>
                    {/* Progress Bar */}
                    <td>
                      <div className="manage-user-progress-bar-container">
                        <div className="manage-user-progress-bar">
                          <div
                            className="manage-user-progress-segment manage-user-delivered"
                            style={{ width: `${deliveredPercent}%` }}
                          ></div>
                          <div
                            className="manage-user-progress-segment manage-user-cancelled"
                            style={{ width: `${cancelledPercent}%` }}
                          ></div>
                          <div
                            className="manage-user-progress-segment manage-user-pending"
                            style={{ width: `${pendingPercent}%` }}
                          ></div>
                          <div
                            className="manage-user-progress-segment manage-user-shipped"
                            style={{ width: `${shippedPercent}%` }}
                          ></div>
                        </div>
                      </div>
                    </td>
                    <td>{user.fname} {user.lname}</td>
                    <td>{user.username}</td>
                    <td>{user.email}</td>
                    <td>{user.phno}</td>
                    <td>{user.orders.length}</td>
                    <td>
                    {editing === user.username ? (
                        <>
                        <div className="form-group security-question">
                          <FaQuestionCircle className="icon" />
                          <select
                            name="securityQuestion"
                            id={`security-question-${user.username}`}
                            className="input1"
                            defaultValue={user.securityQuestion || ''}
                          >
                            <option value="">Select a Security Question</option>
                            <option value="What is your pet's name?">What is your pet's name?</option>
                            <option value="What is your mother's maiden name?">What is your mother's maiden name?</option>
                            <option value="What was your first car?">What was your first car?</option>
                            <option value="What elementary school did you attend?">What elementary school did you attend?</option>
                            <option value="What is the name of your favorite teacher?">What is the name of your favorite teacher?</option>
                          </select>
                        </div>
                        <div className="form-group security-answer">
                          <input
                            type="text"
                            id={`security-answer-${user.username}`}
                            defaultValue={user.securityAnswer || ''}
                            placeholder="Enter your answer"
                            className="input1"
                          />
                        </div>
                        <button onClick={() => handleSaveSecurityQuestion(user.username)} className="save-button">Save</button>
                        <button onClick={handleCancelEdit} className="cancel-button">Cancel</button>
                      </>
                    ) : (
                      <>
                        <span>{user.securityQuestion || 'Not set'}</span>
                        <button onClick={() => handleEditSecurityQuestion(user.username)} className="edit-button">Edit</button>
                      </>
                    )}
                  </td>
                  <td>
                    <button onClick={() => toggleOrdersVisibility(user.username)} className="toggle-button">
                      {showOrders[user.username] ? 'Hide Orders' : 'Show Orders'}
                    </button>
                    <button onClick={() => toggleAddressesVisibility(user.username)} className="toggle-button">
                      {showAddresses[user.username] ? 'Hide Addresses' : 'Show Addresses'}
                    </button>
                  </td>
                </tr>
                {showOrders[user.username] && (
                  <tr>
                    <td colSpan="8">
                        <table className="manage-user-orders-table">
                        <thead>
                            <tr>
                              <th>Order ID</th>
                              <th>Status</th>
                              <th>Order Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {user.orders.map((order) => (
                              <tr key={order.orderId}>
                                <td>{order.orderId}</td>
                                <td>{order.orderStatus}</td>
                                <td>{new Date(order.orderDate).toLocaleDateString()}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )}
                  {showAddresses[user.username] && (
                    <tr>
                      <td colSpan="8">
                        <table className="manage-user-addresses-table">
                        <thead>
                            <tr>
                              <th>Address Line 1</th>
                              <th>Address Line 2</th>
                              <th>City</th>
                              <th>State</th>
                              <th>Postal Code</th>
                              <th>Country</th>
                              <th>Default</th>
                            </tr>
                          </thead>
                          <tbody>
                            {user.addresses.map((address, index) => (
                              <tr key={index}>
                                <td>{address.addressLine1}</td>
                                <td>{address.addressLine2}</td>
                                <td>{address.city}</td>
                                <td>{address.state}</td>
                                <td>{address.postalCode}</td>
                                <td>{address.country}</td>
                                <td>{address.isDefault ? 'Yes' : 'No'}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ManageUsers;