import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './register.css';
import UserReg from '../components/assets/registeruser.png';
import axios from "axios";
import { FaUser, FaPhone, FaWhatsapp } from 'react-icons/fa';

const RegisterPage = () => {
  const [formData, setFormData] = useState({
    fname: '',
    phno: '',
    otp: '',
  });

  const [error, setError] = useState('');
  const [errorField, setErrorField] = useState('');
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false); 
  const [otpCountdown, setOtpCountdown] = useState(0);
  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'phno') {
      const numericValue = value.replace(/\D/g, '');
      if (numericValue.length <= 10) {
        setFormData({ ...formData, [name]: numericValue });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  // Validate phone number
  const validatePhoneNumber = (phno) => {
    return phno.length === 10;
  };

  // Handle sending OTP
  const handleSendOtp = async (e) => {
    e.preventDefault();

    // Ensure phone number is valid before sending OTP
    if (!validatePhoneNumber(formData.phno)) {
      setMessage('Phone number must be 10 digits.');
      return;
    }

    try {
      const response = await axios.post("/api/send-otp", { phoneNumber: formData.phno });
      if (response.data.success) {
        setMessage("OTP sent successfully. Please enter the OTP and click on Register");
        setIsOtpSent(true); // OTP sent successfully, show OTP input
        setOtpCountdown(30);

        // Start a timer for the countdown
      const interval = setInterval(() => {
        setOtpCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(interval); // Stop the timer
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
      } else {
        setMessage(response.data.message);
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      setMessage("Error sending OTP.");
    }
  };

  // Handle registration form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!formData.otp) {
      setMessage('Please enter the OTP');
      return;
    }
  
    try {
      // Step 1: Verify OTP
      const verifyResponse = await axios.post("/api/verify-otp", {
        phoneNumber: formData.phno,
        otp: formData.otp,
      });
  
      if (verifyResponse.data.success) {
        // Step 2: Register User
        const { fname, phno } = formData; // Extract only required fields
        const registerResponse = await fetch('/api/register', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ fname, phno }), // Send only fname and phno
        });
  
        const data = await registerResponse.json();
  
        if (registerResponse.ok) {
          localStorage.setItem('token', data.token);
          navigate('/shop');
          window.location.reload();
        } else {
          setError(data.message);
          setErrorField(data.field);
        }
      } else {
        setMessage(verifyResponse.data.message);
      }
    } catch (error) {
      console.error('Error:', error);
      setError('Error registering user');
    }
  };
  

  return (
    <div className="main">
      <section className="signup">
        <div className="register-container">
          <div className="signup-content">
            <div className="signup-form">
              <h2 className="form-title">Sign Up</h2>
              <form method="post" className="register-form" id="register-form" onSubmit={handleSubmit}>
                <div className="form-group">
                  <FaUser className="icon" />
                  <input
                    type="text"
                    name="fname"
                    id="fname"
                    placeholder="Enter your Full Name"
                    className={`input1 ${errorField === 'fname' ? 'error-input' : ''}`}
                    value={formData.fname}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <FaWhatsapp className="icon-whatsapp" />
                  <input
                    type="text"
                    name="phno"
                    id="phno"
                    placeholder="Enter your Whatsapp no."
                    className={`input1 ${errorField === 'phno' ? 'error-input' : ''}`}
                    value={formData.phno}
                    onChange={handleChange}
                  />
                </div>

                {/* Show OTP input only if OTP has been sent */}
                {isOtpSent && (
                  <div className="form-group">
                    <input
                      type="text"
                      name="otp"
                      id="otp"
                      placeholder="Enter OTP"
                      className={`input1 ${errorField === 'otp' ? 'error-input' : ''}`}
                      value={formData.otp}
                      onChange={handleChange}
                    />
                  </div>
                )}

                <div className="form-group form-button">
                  
                    <button type="button"
                     onClick={handleSendOtp} 
                     className={isOtpSent ? "resend-otp-button": "send-otp-button"}
                     disabled={otpCountdown > 0}>
                      {otpCountdown > 0 ? `Resend OTP (${otpCountdown}s)` : isOtpSent ? "Resend OTP":"Send OTP"}
                    </button>
                    
                    <button type="submit"
                    className="form-submit1"
                    disabled={!isOtpSent}>
                      Register
                    </button>
                  
                </div>
                {message && <div className="error-message">{message}</div>}
              </form>
            </div>
            <div className="signup-image">
              <div className='iconSignup'>
                <img src={UserReg} alt="user registration" />
              </div>
              <div className='signup-image-link'>
                <Link to="/login" className='link-singup'>Already a member? <u>Click here</u></Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default RegisterPage;