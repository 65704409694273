import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './adminPincode.css';

const AdminPincode = () => {
  const [pincode, setPincode] = useState('');
  const [message, setMessage] = useState('');
  const [blockedPincodes, setBlockedPincodes] = useState([]);
  const [blockAllPincodes, setBlockAllPincodes] = useState(false);
  const [loading, setLoading] = useState(false);
 
 
 //Fetch the Pincodes
  const fetchBlockedPincodes = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/api/blocked-pincodes', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setBlockedPincodes(response.data.blockedPincodes || []);
      setBlockAllPincodes(response.data.blockAllPincodes || false);
    } catch (error) {
      console.error('Error fetching blocked pincodes:', error);
      alert('You are not authorized to access this resource.');
    }
  };
  
  useEffect(() => {
    fetchBlockedPincodes();
  }, []);

  // Handle adding a new blocked pincode
  const handleAddBlockedPincode = async (e) => {
    e.preventDefault();
    if (!pincode || !message) {
      alert('Please fill in both pincode and message.');
      return;
    }
  
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      const response = await axios.post(
        '/api/blocked-pincodes', 
        { pincode, message },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      alert(response.data.message);
      setPincode('');
      setMessage('');
      fetchBlockedPincodes();
      setLoading(false);
    } catch (error) {
      alert('Failed to block pincode');
      setLoading(false);
    }
  };
  
  // Handle toggling the global blockAllPincodes status
  const handleToggleBlockAll = async (e) => {
    const newStatus = e.target.checked;
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      await axios.post(
        '/api/blocked-pincodes/toggle',
        { blockAllPincodes: newStatus },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setBlockAllPincodes(newStatus);
      setLoading(false);
    } catch (error) {
      alert('Failed to toggle blockAllPincodes');
      setLoading(false);
    }
  };
  

  return (
    <div className="admin-page">
      <h2>Admin - Manage Blocked Pincodes</h2>
    
      {/* Add Blocked Pincode Form */}
      <div className="form-container">
        <h3>Add Blocked Pincode</h3>
        <form onSubmit={handleAddBlockedPincode}>
          <div>
            <input
              type="text"
              placeholder="Pincode"
              value={pincode}
              onChange={(e) => setPincode(e.target.value)}
              required
            />
          </div>
          <div>
            <textarea
              placeholder="Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            ></textarea>
          </div>
          <button class="block-pincode-btn" type="submit" disabled={loading}>
            {loading ? 'Blocking...' : 'Block Pincode'}
          </button>
        </form>
      </div>
      {/* Checkbox to Toggle Block All Pincodes */}
      <div>
        
        Block All Pincodes:
          <input
            type="checkbox"
            checked={blockAllPincodes}
            onChange={handleToggleBlockAll}
            disabled={loading}
          />
      </div>
      

      {/* Blocked Pincode Table */}
      <div>
        <h3>Blocked Pincodes</h3>
        {blockedPincodes.length === 0 ? (
          <p>No blocked pincodes available.</p>
        ) : (
          <table className="blocked-pincode-table">
            <thead>
              <tr>
                <th>Pincode</th>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
              {blockedPincodes.map((blocked, index) => (
                <tr key={index}>
                  <td>{blocked.pincode}</td>
                  <td>{blocked.message}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default AdminPincode;
