import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import './adminNavbar.css'; // Import the CSS file

const AdminNavbar = () => {
  const { isAdmin, logout } = useAuth();

  return (
    <nav className='adminNav'>
      <ul>
        <li><Link to="/admin/dashboard">Dashboard</Link></li>
        <li><Link to="/admin/products">Products</Link></li>
        <li><Link to="/admin/update-order">Update Orders</Link></li>
        <li><Link to="/admin/manage-users">Manage Users</Link></li>
        <li><Link to="/admin/upload-product">Upload Product</Link></li>
        <li><Link to="/admin/update-product">Update and Add Colors to Product</Link></li>
        <li><Link to="/admin/add-image">Add Image</Link></li>
        <li><Link to="/admin/product-list">Product List</Link></li>
        <li><Link to="/admin/update-metadata">Update Meta Data</Link></li>
        <li><Link to="/admin/user-order">All Orders</Link></li>
        <li><Link to="/admin/admin-register">Register</Link></li>
        <li><Link to="/admin/invoice">Invoice</Link></li>
        <li><Link to="/admin/queries">Queries</Link></li>
        <li><Link to="/admin/admin-return-orders">All Return Orders</Link></li>
        <li><Link to="/admin/update-bulk-orders">Bulk Orders</Link></li>
        <li><Link to="/admin/blocked-pincode">Block Pincode</Link></li>
        {isAdmin && <li><button onClick={logout}>Logout</button></li>}
      </ul>
    </nav>
  );
};

export default AdminNavbar;
