import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import Product from './Product'; // Import the Product component
import { useCart } from '../contexts/CartContext';
import { useParams } from 'react-router-dom'; // Import useParams to extract URL parameters
import './shop.css';

const ShopList = () => {
  const [products, setProducts] = useState([]);
  const [visibleDropdown, setVisibleDropdown] = useState(null);
  const [selectedPriceRange, setSelectedPriceRange] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [lock, setLock] = useState(false);
  const productsPerPage = 16;
  const { addToCart } = useCart();
  const { productId: priorityProductId } = useParams(); // Extract productId from the URL

  // Fetch the base URL from the environment variable or use a fallback
  const baseUrl = process.env.REACT_APP_BASE_URL ;

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${baseUrl}/products`);
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, [baseUrl]);

  const toggleDropdown = (dropdown) => {
    setVisibleDropdown((prevDropdown) => (prevDropdown === dropdown ? null : dropdown));
  };

  const filterByPriceRange = (maxPrice) => {
    setSelectedPriceRange(maxPrice);
    setVisibleDropdown(null);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleAddToCart = (product) => {
    if (!lock) {
      setLock(true);

      const discount = product.listingPrice - product.sellingPrice;

      // Add discount and totalPrice to the product object
      const productWithDiscount = {
        ...product,
        discount,
        totalPrice: product.sellingPrice,  // Add the selling price as total price
      };

      addToCart(productWithDiscount);

      setTimeout(() => {
        setLock(false);
      }, 500);
    }
  };


  const handleProductVisible = useCallback(() => {
    // Perform any additional actions when a product becomes visible
  }, []);

  const renderProducts = () => {
    let filteredProducts = products.filter(product =>
      product.colors.some(color =>
        [0, 1, 2, 3].includes(color.priority) &&
        color.images.length > 0 &&
        (!selectedPriceRange || product.sellingPrice < selectedPriceRange)
      )
    );

    // If there's a priority product, prioritize it by sorting
    if (priorityProductId) {
      filteredProducts = filteredProducts.sort((a, b) => {
        if (a.productId === priorityProductId) return -1; // Priority product comes first
        if (b.productId === priorityProductId) return 1;
        return 0; // Otherwise, keep the normal order
      });
    }

    const paginatedProducts = filteredProducts.slice(currentPage * productsPerPage, (currentPage + 1) * productsPerPage);

    return (
      <div className="product-container">
        {paginatedProducts.map((product) =>
          product.colors.map((color) => {
            if ([0, 1, 2, 3].includes(color.priority) && color.images.length > 0) {
              return (
                <Product
                  key={`${product.productId}-${color.color}`} // Use productId for uniqueness
                  product={product}
                  color={color}
                  onAddToCart={handleAddToCart}
                  onVisible={handleProductVisible}
                />
              );
            } else {
              return null;
            }
          })
        )}
      </div>
    );
  };

  return (
    <div>
      <nav className="filter-navbar">
        <span className="filter-label">Filter By</span>
        <div className="dropdown">
          <div className="dpprice">
            <button className="dropbtnn" onClick={() => toggleDropdown('price')}>
              Price <i className="fas fa-caret-down"></i>
            </button>
          </div>
          {visibleDropdown === 'price' && (
            <div className="dropdown-content fade-in">
              <button onClick={() => filterByPriceRange(499)}>Less than 499</button>
              <button onClick={() => filterByPriceRange(599)}>Less than 599</button>
              <button onClick={() => filterByPriceRange(699)}>Less than 699</button>
              <button onClick={() => filterByPriceRange(799)}>Less than 799</button>
            </div>
          )}
        </div>
      </nav>
      {renderProducts()}
      {/* New Content Section */}
      <div className="shop-description">
        <h2>Discover Premium Bags at Western Flex Shop – Where Style Meets Functionality</h2>
        <p>
          At Western Flex Shop, we bring you a curated collection of high-quality bags that blend luxury, style, and durability. From sophisticated leather handbags to versatile backpacks, every bag is designed to complement your lifestyle. Whether you're commuting to work or planning a weekend getaway, our stylish and durable bags are crafted to meet your needs.
        </p>
        <h3>Why Choose Western Flex Shop for Your Next Bag?</h3>
        <ul>
          <li><strong>Elegant Designs for Every Occasion:</strong> Explore our wide range of trendy and timeless bags, perfect for work, travel, or leisure. Each piece is crafted with attention to detail, ensuring both form and function.</li>
          <li><strong>Crafted with Superior Materials:</strong> Our bags are made from premium materials like authentic leather, eco-friendly fabrics, and durable hardware, ensuring longevity and sustainability.</li>
          <li><strong>Exceptional Craftsmanship:</strong> Every bag at Western Flex Shop is handcrafted by expert artisans, guaranteeing exceptional quality and craftsmanship that stands the test of time.</li>
        </ul>
        <h3>Experience Unmatched Quality and Service</h3>
        <ul>
          <li><strong>Fast & Free Shipping Nationwide:</strong> Enjoy hassle-free shopping with fast, free shipping on all US orders. No hidden costs – just premium bags delivered to your doorstep.</li>
          <li><strong>Sustainability at Heart:</strong> We prioritize sustainability in our manufacturing, offering eco-friendly options that make you feel good about your purchase.</li>
          <li><strong>Dedicated Customer Support:</strong> Our customer service team is here to ensure a smooth shopping experience, from product queries to post-purchase support.</li>
        </ul>
        <h3>The Western Flex Shop Promise</h3>
        <p>
          We stand by the quality of our products. Whether you're looking for a chic evening clutch, a professional tote bag, or a rugged travel backpack, you can count on Western Flex Shop for bags that combine elegance, practicality, and durability.
        </p>
      </div>
    </div>
  );
};

export default ShopList;
