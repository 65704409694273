import React, { useEffect, useState } from 'react';
import './checkout.css';
import { useCart } from '../contexts/CartContext';
import axios from 'axios';
import { FaTrash } from 'react-icons/fa'; // Importing the trash icon

const Checkout = () => {
  const { cart, totalAmount, updateQuantity, setCart } = useCart();
  const [address, setAddress] = useState({
    addressLine1: '',
    addressLine2: '',
    state: '',
    city: '',
    postalCode: '',
    country: 'India',
  });
  const [existingAddresses, setExistingAddresses] = useState([]);
  const [selectedAddressId, setSelectedAddressId] = useState('');
  const [addingNewAddress, setAddingNewAddress] = useState(false);
  const [error, setError] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [showEmptyCartPopup, setShowEmptyCartPopup] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [adjustedTotalAmount, setAdjustedTotalAmount] = useState(totalAmount);
  const [savingsMessage, setSavingsMessage] = useState('');
  const [userInfo, setUserInfo] = useState({
    name: '',
    phoneNumber: ''
  });
  const [isPincodeBlocked, setIsPincodeBlocked] = useState(false); 

  const fetchCityAndState = async (postalCode) => {
    try {
      // Fetch the city and state details from external API
      const response = await axios.get(`https://api.postalpincode.in/pincode/${postalCode}`);
      const data = response.data[0];
  
      if (data && data.Status === 'Success' && data.PostOffice.length > 0) {
        const { District, State } = data.PostOffice[0];
  
        // After fetching city and state, check if the pincode is blocked
        const blockedResponse = await axios.post('/api/blocked-pincodes/check', { pincode: postalCode });
        const blockedData = blockedResponse.data;
  
        if (blockedData.blocked) {
          // Set the blocked status to true and display the message
          setIsPincodeBlocked(true);
          window.alert(`${blockedData.message}`);
          setError(`${blockedData.message}`);
        } else {
          // Set the blocked status to false if the pincode is not blocked
          setIsPincodeBlocked(false);
        }
  
        // Update the city and state
        setAddress((prevState) => ({
          ...prevState,
          city: District,
          state: State,
        }));
      } else {
        setError('Invalid pincode. Please enter a valid pincode.');
        setAddress((prevState) => ({
          ...prevState,
          city: '',
          state: '',
        }));
      }
    } catch (error) {
      console.error('Error fetching city and state:', error);
      setError('Error fetching location details. Please try again.');
    }
  };

  const handleIncreaseQuantity = (item) => {
    updateQuantity(item.title, item.color, 1); // Increase quantity by 1
  };

  // Function to handle quantity decrease
  const handleDecreaseQuantity = (item) => {
    if (item.quantity > 1) { // Ensure quantity doesn’t go below 1
      updateQuantity(item.title, item.color, -1);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('/api/user/me', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setExistingAddresses(response.data.addresses);
        setUserInfo({
          name: response.data.name,
          phoneNumber: response.data.phoneNumber,
        });
        if(response.data.addresses.length === 0){
          setAddingNewAddress(true)
        }
        if (response.data.addresses.length > 0) {
          setSelectedAddressId(response.data.addresses[0].addressId);
        }
      } catch (err) {
        console.error('Error fetching user data:', err);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    if (showPopup || showEmptyCartPopup) {
      const timer = setTimeout(() => {
        setShowPopup(false);
        setShowEmptyCartPopup(false);
      }, 15000);
      return () => clearTimeout(timer);
    }
  }, [showPopup, showEmptyCartPopup]);

  useEffect(() => {
    if (paymentMethod === 'PaidOnline') {
      setAdjustedTotalAmount(totalAmount - 31);
      setSavingsMessage('You saved ₹31 extra!');
    } else {
      setAdjustedTotalAmount(totalAmount);
      setSavingsMessage('');
    }
  }, [paymentMethod, totalAmount]);

  const handleDelete = (item) => {
    updateQuantity(item.title, item.color, -1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Allow only numeric values for postal code with a max length of 6
    if (name === "postalCode") {
      const numericValue = value.replace(/\D/g, ''); // Remove non-numeric characters
      if (numericValue.length <= 6) {
        setAddress(prevState => ({
          ...prevState,
          [name]: numericValue,
        }));

        if (numericValue.length === 6) {
          fetchCityAndState(numericValue);  // Fetch city and state when 6-digit pincode is entered
        }
      }
    } else {
      setAddress(prevState => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleAddressSelect = (addressId) => {
    setSelectedAddressId(addressId);
    setAddingNewAddress(false);
  };

// Monitor address selection and update blocked status
useEffect(() => {
  if (selectedAddressId) {
    const selectedAddress = existingAddresses.find(
      (addr) => addr.addressId === selectedAddressId
    );

    if (selectedAddress) {
      // Check if the pincode of the selected address is blocked
      const checkBlockedStatus = async () => {
        try {
          const response = await axios.post('/api/blocked-pincodes/check', {
            pincode: selectedAddress.postalCode,
          });
          setIsPincodeBlocked(response.data.blocked);
         if(response.data.blocked){
          window.alert(response.data.message);
         }
        } catch (error) {
          console.error('Error checking pincode status:', error);
        }
      };

      checkBlockedStatus();
    }
  }
  // Reset payment method whenever address changes
  setPaymentMethod('');
}, [selectedAddressId])

  const handleAddAddress = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.put(`/api/user/update/${localStorage.getItem('username')}`, {
        addresses: [...existingAddresses, { ...address, addressId: Date.now().toString() }]
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setExistingAddresses(response.data.addresses);
      setAddingNewAddress(false);
      setSelectedAddressId(response.data.addresses[response.data.addresses.length - 1].addressId);
    } catch (err) {
      console.error('Error adding address:', err);
      setError('Error adding address.');
    }
  };

  const handlePayment = async (orderData) => {
    try {
      const { data } = await axios.post('/api/create-order', {
        amount: adjustedTotalAmount,
      });

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY, // Store this in .env
        amount: data.amount,
        currency: data.currency,
        name: 'WESTERN FLEX',
        description: 'Order Description',
        order_id: data.orderId,
        handler: async (response) => {
          const { razorpay_order_id, razorpay_payment_id, razorpay_signature } = response;

          const verifyResponse = await axios.post('/api/verify-payment', {
            orderId: razorpay_order_id,
            paymentId: razorpay_payment_id,
            signature: razorpay_signature,
          });
          
          if (verifyResponse.data.success) {
            try {
              await fetch('/api/orders', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + localStorage.getItem('token'),
                },
                body: JSON.stringify(orderData),
              });
              setCart([]);
              setShowPopup(true);
            } catch (error) {
              console.error('Error placing order:', error);
              setError('Error placing order.');
            }
          } else {
            setError('Payment verification failed.');
          }
        },
        prefill: {
          name: userInfo.name,
          email: 'customer@example.com',
          contact: userInfo.phoneNumber,
        },
        theme: {
          color: '#3399cc',
        },
      };

      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error('Error creating Razorpay order:', error);
      setError('Error creating Razorpay order.');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    if (cart.length === 0) {
      setShowEmptyCartPopup(true);
      return;
    }
  
    if (!selectedAddressId && !addingNewAddress) {
      setError('Please select or add an address.');
      return;
    }
  
    if (isPincodeBlocked && paymentMethod === 'COD') {
      setError('COD is not available for the selected address.');
      window.alert("Not available");
      return;
    }
    
    const orderAddress = addingNewAddress
      ? address
      : existingAddresses.find((addr) => addr.addressId === selectedAddressId);
  
    const orderData = {
      address: orderAddress,
      items: cart,
      totalAmount: adjustedTotalAmount,
      orderStatus: 'pending',
      paymentMethod: paymentMethod === 'PaidOnline' ? 'Prepaid' : 'COD',
    };
  
    if (paymentMethod === 'PaidOnline') {
      handlePayment(orderData);
    } else {
      // Place order logic
      fetch('/api/orders', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
        body: JSON.stringify(orderData),
      })
        .then((response) => {
          if (response.ok) {
            setCart([]);
            setShowPopup(true);
          } else {
            return response.json().then((data) => {
              setError(data.error || 'Error placing order.');
            });
          }
        })
        .catch((error) => {
          console.error('Error placing order:', error);
          setError('Error placing order.');
        });
    }
  };
  
  return (
    <div>
      <header className="checkout-header">
        <div className="container">
          <h1>Checkout</h1>
        </div>
      </header>

      <div className="checkout-container">
      <section className="order-summary">
  {/* PC View Order Summary */}
  <div className="order-summary-pc">
  <h2>Order Summary</h2>
            <table>
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Image</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Discount</th>
                  <th>Total</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {cart.map((item, index) => (
                  <tr key={index}>
                    <td>{item.title}</td>
                    <td><img src={item.image} alt={item.title} className="checkout-image" /></td>
                    <div className="quantity-controls">
                    <p>Quantity</p>
                    <button onClick={() => handleDecreaseQuantity(item)} className="quantity-button">-</button>
                    <p className="quantity-display">{item.quantity}</p>
                    <button onClick={() => handleIncreaseQuantity(item)} className="quantity-button">+</button>
                 </div>
                    <td>INR {item.listingPrice.toFixed(2)}</td>
                    <td>INR {item.discount.toFixed(2)}</td>
                    <td>{item.totalPrice.toFixed(2)}</td>
                    <td>
                      <button className="delete-button" onClick={() => handleDelete(item)}>Delete</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
                <div className="total">
      Total: <span>INR {adjustedTotalAmount.toFixed(2)}</span>
    </div>
    {paymentMethod === 'PaidOnline' && (
      <div className="savings-message">
        <p style={{ color: 'green', fontWeight: 'bold' }}>{savingsMessage}</p>
      </div>
    )}
  </div>

  {/* Mobile View Order Summary */}
  <div className="order-summary-mobile">
    <h2>Order Summary</h2>
    <div className="cart-items">
      {cart.map((item, index) => (
        <div className="cart-item" key={index}>
          <img src={item.image} alt={item.title} className="checkout-image" />
          <div className="item-details">
            <h4>{item.title}</h4>
            <div className="quantity-controls">
              <p>Quantity</p>
              <button onClick={() => handleDecreaseQuantity(item)} className="quantity-button">-</button>
              <p className="quantity-display">{item.quantity}</p>
              <button onClick={() => handleIncreaseQuantity(item)} className="quantity-button">+</button>
            </div>

            <p>Price {item.listingPrice.toFixed(2)}</p>
            <p>Discount {item.discount.toFixed(2)}</p>
            <p>Total {item.totalPrice.toFixed(2)}</p>
            <button className="delete-button" onClick={() => handleDelete(item)}>
              <FaTrash size={16} />
            </button>
          </div>
        </div>
      ))}
    </div>
    <div className="total">
      Total: <span>INR {adjustedTotalAmount.toFixed(2)}</span>
    </div>
  </div>
</section>

      </div>

      <div className="checkout-container">
        <div className="checkout-content">
          <form onSubmit={handleSubmit}>
            <div className="delivery-address">
              <h2>1. Delivery Address</h2>
              <div>
                {existingAddresses.length > 0 && (
                  <div className="address-selection">
                    <label>Select an existing address:</label>
                    {existingAddresses.map(address => (
                      <div 
                        key={address.addressId} 
                        className={`address-box ${selectedAddressId === address.addressId ? 'selected' : ''}`}
                        onClick={() => handleAddressSelect(address.addressId)}
                      >
                        <p>{address.addressLine1}</p>
                        {address.addressLine2 && <p>{address.addressLine2}</p>}
                        <p>{address.city}, {address.state}, {address.postalCode}</p>
                        <p>{address.country}</p>
                      </div>
                    ))}
                  </div>
                )}
                <button type="button" className='buttonAddAdress' onClick={() => setAddingNewAddress(true)}>Add New Address</button>
                {existingAddresses.length === 0 && !addingNewAddress && <div>No addresses found.</div>}
                {addingNewAddress && (
                  <div className="new-address-form">
                    <p className='add-address-label'>Add New Address</p>
                    <div>
                    <label> House Name , House Number , floor  , Street Name , Street Address ,  Area  </label>
                      <input type="text" name="addressLine1" value={address.addressLine1} onChange={handleChange} required />
                    </div>
                    <div>
                    <label>Apartment,Building or near by Landmark </label>
                      <input type="text" name="addressLine2" value={address.addressLine2} onChange={handleChange} required />
                    </div>
                    <div>
                      <label>Postal Code</label>
                      <input type="text" name="postalCode" value={address.postalCode} onChange={handleChange} required />
                    </div>
                    <div>
                      <label>City</label>
                      <input type="text" name="city" value={address.city} onChange={handleChange} required />
                    </div>
                    <div>
                      <label>State</label>
                      <input type="text" name="state" value={address.state} onChange={handleChange} required />
                    </div>
                    
                    <div>
                      <label>Country</label>
                      <input type="text" name="country" value={address.country} onChange={handleChange} required />
                    </div>
                    <button type="button" onClick={handleAddAddress}>Save Address</button>
                    <button type="button" onClick={() => setAddingNewAddress(false)}>Cancel</button>
                  </div>
                )}
                
              </div>
            </div>

            <div className="payment-method">
  <h2>2. Select a Payment Method</h2><br />
  <div className="payment-options">
    <label className="custom-radio">
      <input 
        type="radio" 
        name="paymentMethod" 
        value="cashOnDelivery"
        disabled={isPincodeBlocked}
        checked={paymentMethod === 'cashOnDelivery'}
        onChange={() => setPaymentMethod('cashOnDelivery')}
      />
     
      <span className="radio-mark"></span>
      Cash on Delivery
    </label>
     {error && <div className="error">{error}</div>}
    {paymentMethod === 'cashOnDelivery' && (
      <p className="save-message">
        Pay online and save ₹31 more on your product.
      </p>
    )}
    <br />
    <label className="custom-radio">
      <input 
        type="radio" 
        name="paymentMethod" 
        value="PaidOnline"
         checked={paymentMethod === 'PaidOnline'}
        // onChange={() => handlePaymentMethodChange('PaidOnline')}
        onChange={() => setPaymentMethod('PaidOnline')}
        // FOR TESTING
      />
      <span className="radio-mark"></span>
      <span>Pay Online</span>
      <span className="save">(Save ₹31!)</span>
    </label>
  </div>
</div>

            <div className="place-order">
              <button type="submit" className='orderBtn'>Place Order</button>
            </div>

            {error && <div className="error-message"></div>}
          </form>
        </div>
      </div>

      {showPopup && (
    <div className="popup">
    <p>Your order has been placed successfully! You can expect a confirmation message within 4-5 hours.</p>
    <button onClick={() => setShowPopup(false)} className="close-button">X</button>
    </div>
    )}

    {showEmptyCartPopup && (
   <div className="popup">
    <p>Your cart is empty. Please add items to your cart before proceeding.</p>
    <button onClick={() => setShowEmptyCartPopup(false)} className="close-button">X</button>
    </div>
    )}
    </div>
  );
};

export default Checkout;
