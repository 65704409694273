import React, { useState } from 'react';
import './contactUs.css';

const ContactUs = () => {
    const [formData, setFormData] = useState({ name: '', email: '', phone: '', message: '' });
    const [responseMessage, setResponseMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;

        // Enforce restriction only for the phone field
        if (name === 'phone') {
            const phoneRegex = /^[0-9]{0,10}$/; // Allow only up to 10 digits
            if (!phoneRegex.test(value)) {
                return; // Do not update the state if input is invalid
            }
        }

        setFormData({ ...formData, [name]: value });
    };

    const validateForm = () => {
        const phoneRegex = /^[0-9]{10}$/; // Ensures only 10-digit numbers
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email validation

        if (!phoneRegex.test(formData.phone)) {
            setResponseMessage('Phone number must be a 10-digit number without spaces or letters.');
            return false;
        }

        if (!emailRegex.test(formData.email)) {
            setResponseMessage('Please enter a valid email address.');
            return false;
        }

        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        try {
            const response = await fetch('/api/submit-query', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            const data = await response.json();

            if (response.status === 201) {
                setResponseMessage(data.message);
                setFormData({ name: '', email: '', phone: '', message: '' });
            } else {
                setResponseMessage(data.message);
            }
        } catch (error) {
            setResponseMessage('Error submitting query');
        }
    };

    return (
        <div className="main">
            <div className="contact-us">
                <h2>Contact Us</h2>
                <form className="contact-form" onSubmit={handleSubmit}>
                    <label>
                        Name:
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <label>
                        E-mail:
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <label>
                        Phone:
                        <input
                            type="tel"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <label>
                        Message:
                        <textarea
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            required
                        ></textarea>
                    </label>
                    <button type="submit" className="contactBtn">
                        Send
                    </button>
                </form>
                {responseMessage && <p className="response-message">{responseMessage}</p>}
            </div>
        </div>
    );
};

export default ContactUs;
